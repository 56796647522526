import axios from "@axios"

export const setTrainings = "setTrainings"
export default {
  state: {
    trainings: []
  },
  getters: {
    getTrainings(state){
      return state.trainings
    },
    getTrainingById:(state) => (id) => {
      return this.state.trainings.find(t => {return t.id === id})
    }
  },
  actions:{
    fetchTrainings(ctx) {

      return new Promise((resolve, reject) => {
        axios
          .get('/hro/orientation/Trainings')
          .then(response => {
            ctx.commit(setTrainings,response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchTraining(ctx,{id}){
      return new Promise((resolve,reject)=>{
        axios.post('/hro/orientation/GetTrainingById', {id:id})
          .then(response => resolve(response))
          .catch(error=> {
            //console.log("hataa")
            reject(error)})
      })
    },
    createTraining(ctx, trainingData){
      return new Promise((resolve,reject) => {
        axios.
          post('/hro/orientation/CreateTraining', {
            name: trainingData.name,
            description: trainingData.description,
            room: trainingData.room,
            trainer: trainingData.trainer,
            startDate: new Date(trainingData.startDate).toLocaleString("en-US"),
            endDate: new Date(trainingData.endDate).toLocaleString("en-US"),
            trainingTypeId: trainingData.selectedType ? trainingData.selectedType.id : 0


        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cloneTraining(ctx, trainingData){
      return new Promise((resolve,reject) => {
        axios.
        post('/hro/orientation/CloneTraining', {
          id: trainingData.id,
          startDate: new Date(trainingData.startDate).toLocaleString("en-US"),
          endDate: new Date(trainingData.endDate).toLocaleString("en-US")

        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTraining(ctx,trainingData){
      return new Promise((resolve,reject) => {
        axios
          .put('/hro/orientation/UpdateTraining', {
            id: trainingData.id,
            name: trainingData.name,
            description: trainingData.description,
            room: trainingData.room,
            trainer: trainingData.trainer,
            startDate: trainingData.startDate.toLocaleString(),
            endDate: trainingData.endDate.toLocaleString(),
            trainingTypeId: trainingData.selectedType ? trainingData.selectedType.id : 0


        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTraining(ctx,{id}){
      return new Promise((resolve,reject) => {
        axios.put('/hro/orientation/DeleteTraining',  { id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVideos(ctx) {

      return new Promise((resolve, reject) => {
        axios
          .get('/hro/Video/Videos')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchVideo(ctx,{id}){
      return new Promise((resolve,reject)=>{
        axios.post('/hro/video/GetVideoById', {id:id})
          .then(response => resolve(response))
          .catch(error=> {
            //console.log("hataa")
            reject(error)})
      })
    },
    createVideo(ctx, videoData){
      return new Promise((resolve,reject) => {
        axios.
        post('/hro/video/CreateVideo', {
          name: videoData.name,
          description: videoData.description,
          order: videoData.order,
          fileName: videoData.fileName,
          filePath: videoData.filePath,
          // uploadDate: videoData.startDate.toLocaleString(),
          // endDate: videoData.endDate.toLocaleString(),

        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVideo(ctx,videoData){
      return new Promise((resolve,reject) => {
        axios
          .put('/hro/video/UpdateVideo', {
            id: videoData.id,
            name: videoData.name,
            description: videoData.description,
            order: videoData.order,
            fileName: videoData.fileName,
            filePath: videoData.filePath,
            isActive: videoData.isActive

          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVideo(ctx,{id}){
      return new Promise((resolve,reject) => {
        axios.put('/hro/video/DeleteVideo',  { id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContacts(ctx) {

      return new Promise((resolve, reject) => {
        axios
          .get('/hro/orientation/Contacts')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchContact(ctx,{id}){
      return new Promise((resolve,reject)=>{
        axios.post('/hro/orientation/GetContactById', {id:id})
          .then(response => resolve(response))
          .catch(error=> {

            reject(error)})
      })
    },
    createContact(ctx, contactData){
      return new Promise((resolve,reject) => {
        axios.
        post('/hro/orientation/CreateContact', {
          name: contactData.name,
          surname: contactData.surname,
          department: contactData.department,
          phoneNumber: contactData.phoneNumber,
          title: contactData.title,


        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateContact(ctx,contactData){
      return new Promise((resolve,reject) => {
        axios
          .put('/hro/orientation/UpdateContact', {
            id: contactData.id,
            name: contactData.name,
            surname: contactData.surname,
            department: contactData.department,
            phoneNumber: contactData.phoneNumber,
            title: contactData.title,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteContact(ctx,{id}){
      return new Promise((resolve,reject) => {
        axios.put('/hro/orientation/DeleteContact',  { id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMembers(ctx){
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/f/cm/getallmembers')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAssignedMembersToTraining(ctx, {id}) {

      return new Promise((resolve, reject) => {

        axios
          .post('/hro/orientation/GetEmployeesByTrainingId', {id:id})
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    assignMembersToTraining(ctx, clientData){
      return new Promise((resolve,reject) => {
        axios.
        post('/hro/orientation/AssignEmployeeToTraining',
          {
            trainingId: clientData.trainingId,
            firstName: clientData.firstName,
            lastName: clientData.lastName,
            photo: clientData.photo,
            memberCode: clientData.memberCode,
            userGuid: clientData.userGuid,
            additionalInfo: clientData.additionalInfo,
            mobile: clientData.mobile
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAssignedMembersFromTraining(ctx, {id}){
      return new Promise((resolve,reject) => {
        axios.put('/hro/orientation/DeleteEmployeeFromTraining',  { id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
  mutations: {
    [setTrainings](state, data){
      state.trainings = data
    }
  }
}
