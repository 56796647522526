<template>
  <div>
    <b-form>
      <b-row>

        <!-- Field: VideoName -->
        <b-col
            cols="12"
            md="6"
        >
          <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
          >
            <b-form-group>
              <h5>Video Name</h5>
              <b-form-input
                  id="name"
                  v-model="videoData.name"
                  :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>
        <!-- Field: Description -->
        <b-col
            cols="12"
            md="6"
        >

          <b-form-group>
            <h5>Description</h5>
            <b-form-textarea
                v-model = "videoData.description"
                id="description"
                placeholder="Textarea"
                rows="3"

            />
          </b-form-group>


        </b-col>
        <!-- Field: FileName -->
        <b-col
            cols="12"
            md="6"
        >
          <b-form-group>
            <h5>File Name</h5>
            <b-form-input
                id="fileName"

                v-model="videoData.fileName"
            />
          </b-form-group>


        </b-col>
        <!-- Field: FilePath -->
        <b-col
            cols="12"
            md="6"
        >
          <validation-provider
              #default="validationContext"
              name="File Name"
              rules="required"
          >
          <b-form-group>
            <h5>File Path</h5>
            <b-form-input
                id="filePath"
                v-model="videoData.filePath"
                :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

        </b-col>
        <!-- Field: Order -->
        <b-col
            cols="12"
            md="6"
        >
          <b-form-group>
            <h5>Order</h5>

            <b-form-spinbutton
                id="sb-vertical"
                v-model="videoData.order"
                inline
            />
          </b-form-group>


        </b-col>


        <!-- Field: Is Active? -->
        <b-col
            cols="12" md="6">
          <b-form-group>
            <h5>Is Active?</h5>


            <b-form-select
                v-model="videoData.isActive"
                :options="activeOptions"
                size="lg"
                class="mt-1"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Upload Date -->
<!--        <b-col-->
<!--            cols="12" md="6">-->
<!--          <b-form-group>-->
<!--            <h5>Upload Date&Time</h5>-->
<!--            <flat-pickr-->
<!--                v-model="videoData.uploadDate"-->
<!--                class="form-control"-->
<!--                :config="{ enableTime: true,minuteIncrement:1}"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->

      </b-row>
    </b-form>
    <!-- Action Buttons -->
    <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="onSubmit"
    >
      Save Changes
    </b-button>
    <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :to="{name: 'videos-page'  }"
    >
      Cancel
    </b-button>
  </div>
</template>

<script>
  import {
   BFormTextarea,BFormSpinbutton, BCardBody, BFormInvalidFeedback,  BTab,  BTabs,BForm,   VBToggle,VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
export default {
  name: 'VideoEditTab',
  components: {
    flatPickr,
    ToastificationContent,
    VBToggle, VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BFormTextarea,BFormSpinbutton, BCardBody, BFormInvalidFeedback,  BTab,  BTabs,BForm, BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar,
    ValidationProvider,
    ValidationObserver,
     
  },
    props: {
    videoData: {
      type: Object,
      required: true,

    }
  },
  setup(props){
    const blankVideoData = {
      Name: '',
      Description: '',
      Order: 0,
      FileName: '',
      UploadDate: null,
      IsActive: {text: "",value: null},
      FilePath: '',


    }

    const videoData = ref(JSON.parse(JSON.stringify(blankVideoData)))
    const resetvideoData = () => {
      videoData.value = JSON.parse(JSON.stringify(blankVideoData))
    }
    const activeOptions = [true,false]
    const toast = useToast()
    const onSubmit = () => {

      store.dispatch('updateVideo',props.videoData)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Success updating video',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            router.push({name:'videos-page'})
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating video',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })

          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetvideoData)

    return {
      onSubmit,
      activeOptions,
      refFormObserver,
      getValidationState,
      resetForm,


    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
