<template>
  <b-card>
        <video-edit-tab :video-data="videoData"  class="mt-2 pt-75" />
  </b-card>


</template>

<script>
import VideoEditTab from '@/views/hr/orientation/video/VideoEditTab'
import {
  VBToggle,  VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import orientationStoreModule from '@/views/hr/orientation/orientationStoreModule'

export default {
  name: 'video-edit',
  components: {

    VideoEditTab, VBToggle, VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar,
  },
  data(){
    return {
      id: router.currentRoute.params.id

    }
  },
  setup(){
    const videoData =ref(null)

    const VIDEO_APP_STORE_MODULE = 'hr-video'
    // Register module
    if (!store.hasModule(VIDEO_APP_STORE_MODULE)) store.registerModule(VIDEO_APP_STORE_MODULE, orientationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VIDEO_APP_STORE_MODULE)) store.unregisterModule(VIDEO_APP_STORE_MODULE)
    })

    store.dispatch('fetchVideo', { id: router.currentRoute.params.id })
        .then(response => { videoData.value = response.data })
        .catch(error => {

          if (error.response.status === 404) {
            videoData.value = undefined
          }
        })

    return {
      videoData
    }
  }

}
</script>

<style scoped>

</style>
